import React from 'react'
import { InputBase } from '@material-ui/core'
import styles from './TextfieldStyled.module.css'
import {makeStyles} from '@material-ui/core';
import { unset } from 'lodash';



function TextfieldStyled({placeholder,name,type,width,color,label,onChangehandler,value,error,helpertext,icon,id,onClick,rounded,small}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width:width,
      borderRadius: rounded?'20px':'5px',
      backgroundColor:color?color:unset,
      fontSize:small?'12px':'14px',
      paddingLeft:'8px',
      paddingRight:rounded?'8px':'0px',
      border: '1px solid var(--ta-grey-dark-light)', // normal border color
      '&.Mui-focused': {
        borderColor: 'var(--ta-primary-color)',
        outline: '3px solid var(--ta-primary-color-light-very)'// focused border color
      },
      '&.Mui-error': {
        borderColor: 'red',
        outline: '3px solid #FFD6D7'// focused border color
      },
    },
  }));
  const classes=useStyles();
  return (
    <div className={styles.mainContainer} style={{width:width}}>
        {label&&<div className={styles.label}>{label}</div>}
        <InputBase name={name} endAdornment={icon?<div style={{display:'flex',alignItems:'center',scale:small?'0.8':'1'}}>{icon}</div>:<></>} className={classes.root} onClick={onClick}  size="small"  id={id} value={value} onChange={onChangehandler} type={type}  placeholder={placeholder} error={error}/>
        
        {error && <div className={styles.error}>{helpertext}</div>}
    </div>
  )
}

export default React.memo(TextfieldStyled)